var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-0"},[_c('v-card-title',[_vm._v(" Vendor Inquiry List "),_c('v-spacer'),_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],staticClass:"pa-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{key:_vm.items.name,staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.items.results,"server-items-length":_vm.items.totalResults,"loading":_vm.loading,"options":_vm.pagination,"footer-props":{'items-per-page-options':[30, 45, 60, -1]}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.createdAt).format("MM/DD/YYYY"))+" ")]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comments))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }