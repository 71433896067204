<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Vendor Inquiry List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-debounce:500ms="getItems"
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.results"
        :server-items-length="items.totalResults"
        :loading="loading"
        :key="items.name"
        :options.sync="pagination"
        :footer-props="{'items-per-page-options':[30, 45, 60, -1]}"
        class="elevation-1"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ moment(item.createdAt).format("MM/DD/YYYY") }}
        </template>
        <template v-slot:item.comments="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-information</v-icon>
              </v-btn>              
            </template>
            <span>{{item.comments}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { parseParams, getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "Inquiry",
  mounted() {
    this.getItems();
  },
  data() {
    return {
      loading: false,
      dialog: false,
      inquiry: {
        name: null,
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Company Name", value: "company_name" },
        { text: "Contact Name", value: "contact_name" },
        { text: "Email Address", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Preferred", value: "contact_method.name", sortable: false },
        { text: "For", value: "vendor_id.company_name", sortable: false },
        { text: "Inquired On", value: "createdAt" },
        { text: "Comment", value: "comments", sortable: false },
      ],
      pagination: {
        itemsPerPage: 30,
        sortBy: ["createdAt"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "contact_name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = true;
      let url = parseParams(this.pageData);
      this.$axios
        .get("admin/vendor_inquiry/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.inquiry = item;
    },
  },
};
</script>